import { useCallback, useState } from "react";
import { Button, Group, Stack, Text } from "@mantine/core";
import { ImageTarget, LinkError, useImageUpload } from "hooks/useImageUpload";
import { useTranslation } from "react-i18next";
import { useUserTracks } from "hooks/useUserTracks";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { captureMessage } from "@sentry/react";

export interface RemoveImageModalProps {
  extraMessage?: string,
  link: string;
  onClose: () => void;
  target?: ImageTarget;
  onError?: (message?: string) => void;
  onSuccess?: () => void;
}

export const RemoveImageModal = ({
  extraMessage,
  link,
  onClose,
  target,
  onError,
  onSuccess,
}: RemoveImageModalProps) => {
  const { remove } = useImageUpload();
  const { updateTrack, selectedTrack } = useUserTracks();
  const { updateWaypoint, selectedWaypoint } = useUserWaypoints();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const removeLink = useCallback(
    async (link: string) => {
      try {
        if (target === "track" && selectedTrack) {
          await updateTrack(selectedTrack.id, {
            links: selectedTrack.links.filter((link_) => link_ !== link),
          });
        } else if (target === "waypoint" && selectedWaypoint) {
          await updateWaypoint(selectedWaypoint.id, {
            links: selectedWaypoint.links.filter((link_) => link_ !== link),
          });
        }
      } catch {
        throw new LinkError();
      }
    },
    [selectedTrack, selectedWaypoint, target, updateTrack, updateWaypoint]
  );

  const removeImage = useCallback(async () => {
    if (link) {
      const regex = /=(.*)/;
      const match = link.match(regex);
      const imgName = match ? match[1] : undefined;

      if (imgName) {
        setLoading(true);

        try {
          await remove(imgName);
          await removeLink(link);

          if (onSuccess) {
            onSuccess();
          } else {
            onClose();
          }
        } catch (error: unknown) {
          if (error instanceof LinkError) {
            captureMessage(
              `Trackbook: ImageError: Error when deleting image - the image was deleted but we couldn't detele the link: ${link}`
            );

            if (onError) {
              onError(t("remove_image_modal.link_remove_failure_text"));
            } else {
              onClose();
            }
          } else {
            if (onError) {
              onError();
            } else {
              onClose();
            }
          }
        }
      } else {
        if (onError) {
          onError();
        } else {
          onClose();
        }
      }
    } else {
      if (onError) {
        onError();
      } else {
        onClose();
      }
    }
  }, [onClose, remove, removeLink]);

  return (
    <Stack>
      <Text size="sm">{t("remove_image_modal.text")}</Text>
      {extraMessage !== undefined ? <Text size="sm">{extraMessage}</Text> : null}
      <Group spacing="xs" position="right">
        <Button
          disabled={loading}
          color="gray"
          variant="filled"
          onClick={() => onClose()}
        >
          {t("generic.no")}
        </Button>
        <Button
          loading={loading}
          color="red"
          variant="filled"
          onClick={() => removeImage()}
        >
          {t("generic.yes")}
        </Button>
      </Group>
    </Stack>
  );
};
