import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  Group,
  Image as MantineImage,
  Select,
  Spoiler,
  Stack,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { SelectIconDisplayItem } from "components/LeafletMap/WaypointInfoPopup/SelectItem";
import { LinksEditor } from "components/UI/LinksEditor";
import { useGenericModals } from "hooks/useGenericModals";
import { useImagePreload } from "hooks/useImagePreload";
import {
  CreateWaypointData,
  UpdateWaypointData,
  useUserWaypoints,
} from "hooks/useUserWaypoints";
import { useTranslation } from "react-i18next";
import { Waypoint } from "types/app";
import { waypointIcons } from "utils/helpers/icons/icons";
import { getIconsWithUniqueNames } from "utils/helpers/waypointIcons/getWithUniqueNames";
import { normalizeIconName } from "utils/helpers/waypointIcons/normalizeUniqueName";

const waypointIconsUniqueNames = getIconsWithUniqueNames(waypointIcons);

const selectData = waypointIconsUniqueNames.map((icon) => ({
  value: icon.name,
  label: icon.key,
  iconName: icon.name,
}));

type Props = {
  waypoint: Waypoint;
};

type Status = "saving" | "duplicating" | null;

export const EditWaypointModal = ({
  context,
  id,
}: ContextModalProps<Props>) => {
  const { selectedWaypoint } = useUserWaypoints();
  // This is a TS hackifx, unfortunately, since the coding inside the hook is not the best
  const waypoint = selectedWaypoint as Waypoint;

  const [status, setStatus] = useState<Status>(null);
  const iconName = waypointIconsUniqueNames.find(
    (uniqueIcon) => uniqueIcon.key === waypoint.icon
  )?.name;
  const [selectedIcon, setSelectedIcon] = useState(
    iconName ?? "map_icon_default"
  );
  const [links, setLinks] = useState(waypoint.links);
  const { updateWaypoint, deleteWaypoints, createWaypoint } =
    useUserWaypoints();
  const { openConfirmWaypointDeleteModal } = useGenericModals();
  useImagePreload(
    selectData.map(
      (data) => `img/map_icons/${normalizeIconName(data.iconName)}.png`
    )
  );
  const { t } = useTranslation();

  useEffect(() => {
    setLinks(waypoint.links);
  }, [waypoint.links]);

  const form = useForm({
    initialValues: {
      name: waypoint.name,
      desc: waypoint.desc,
      src: waypoint.src,
      cmt: waypoint.cmt,
      type: waypoint.type,
    },
  });

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setStatus("saving");

    const selected = selectData.find((icon) => icon.value === selectedIcon);
    const icon = waypointIcons.find(
      (waypointIcon) => waypointIcon.key === selected?.label
    );

    const data: UpdateWaypointData = {
      ...form.values,
      links,
      icon: icon?.key ?? "",
    };
    try {
      await updateWaypoint(waypoint.id, data);
    } catch (error) {
      console.error("error updating waypoint");
    }
    context.closeModal(id);
  };

  const handleDelete = () => {
    openConfirmWaypointDeleteModal({
      count: 1,
      onConfirm: () => {
        deleteWaypoints(waypoint.id);
        context.closeModal(id);
      },
    });
  };

  const duplicate = () => {
    setStatus("duplicating");
    const icon = waypointIcons.find(
      (waypointIcon) => waypointIcon.name === selectedIcon
    );
    const data: CreateWaypointData = {
      ...form.values,
      links,
      icon: icon?.key ?? "",
      point: waypoint.point,
    };
    try {
      createWaypoint(data);
    } catch (error) {
      console.error("failed duplicating waypoint");
    }
    context.closeModal(id);
  };

  return (
    <form onSubmit={handleSave}>
      <Stack>
        <TextInput
          data-autofocus
          required
          label={t("waypoint_edit_modal.options.nameLabel")}
          {...form.getInputProps("name")}
          data-lpignore="true"
          disabled={!!status}
        />
        <Textarea
          autosize
          minRows={3}
          maxRows={6}
          label={t("waypoint_edit_modal.options.descriptionLabel")}
          {...form.getInputProps("desc")}
          disabled={!!status}
        />

        <LinksEditor
          target="waypoint"
          onEdit={setLinks}
          links={links}
          disabled={!!status}
        />

        <Select
          dropdownPosition="top"
          data-lpignore="true"
          label={t("waypoint_edit_modal.options.iconLabel")}
          placeholder={t(
            "waypoint_edit_modal.options.iconSelect.searchbox_placeholder"
          )}
          searchable
          nothingFound={t(
            "waypoint_edit_modal.options.iconSelect.no_results_found"
          )}
          data={selectData}
          itemComponent={SelectIconDisplayItem}
          value={selectedIcon}
          onChange={(name: string) => setSelectedIcon(name)}
          icon={
            selectedIcon ? (
              <MantineImage
                width={18}
                src={`img/map_icons/${normalizeIconName(selectedIcon)}.png`}
              />
            ) : null
          }
          disabled={!!status}
        />

        <Spoiler
          showLabel={t("edit_modals_common.show_advanced_fields")}
          maxHeight={0}
          hideLabel={t("edit_modals_common.hide_advanced_fields")}
        >
          <Stack mb="xs">
            <TextInput
              label={t("edit_modals_common.cmt_label")}
              {...form.getInputProps("cmt")}
            />
            <TextInput
              label={t("edit_modals_common.src_label")}
              {...form.getInputProps("src")}
            />
            <TextInput
              label={t("edit_modals_common.type_label")}
              {...form.getInputProps("type")}
            />
          </Stack>
        </Spoiler>

        <Divider />

        <Group spacing="xs" noWrap>
          <Box sx={{ flexGrow: 1 }}>
            <Button color="red" onClick={handleDelete} disabled={!!status}>
              {t("generic.delete_button_label")}
            </Button>
          </Box>
          <Button
            onClick={duplicate}
            loading={status === "duplicating"}
            disabled={!!status && status !== "duplicating"}
            variant="outline"
          >
            {t("generic.duplicate_button_label")}
          </Button>
          <Button
            type="submit"
            loading={status === "saving"}
            disabled={!!status && status !== "saving"}
          >
            {t("generic.save_button_label")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
