import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Text, ThemeIcon } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { useTranslation } from 'react-i18next'

export type VerifyEmailModalType = 'initial' | 'error'

export const VerifyEmailModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ type: VerifyEmailModalType; message: string }>) => {
  const isTypeLogin = innerProps.type === 'error'
  const { t } = useTranslation()
  const signUpTitle = t('verify_email_modal.verify_title')
  const loginTitle = t('verify_email_modal.not_verified_title')
  return (
    <Stack align="center">
      <ThemeIcon size={70} variant="light" color={isTypeLogin ? 'red' : 'blue'} radius={100} sx={{ fontSize: 35 }}>
        <FontAwesomeIcon icon={faEnvelope} />
      </ThemeIcon>
      <Text size="xl" weight={700}>
        {isTypeLogin ? loginTitle : signUpTitle}
      </Text>
      <Text align="center">{innerProps.message}</Text>
      <Button fullWidth onClick={() => context.closeModal(id)}>
        {t('generic.ok')}
      </Button>
    </Stack>
  )
}
