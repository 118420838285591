const specialChars = ["¢", "£", "¥", "€", "©", "®", "<", ">", '"', "&", "'"];

function contains(text: string) {
  for (const char of specialChars) {
    if (text.includes(char)) {
      return true;
    }
  }
  return false;
}

export function wrap(text: string) {
  return contains(text) ? `<![CDATA[${text}]]>` : text;
}
