import React, { useCallback, useState } from "react";

import Lightbox, {
  addToolbarButton,
  IconButton,
  createIcon,
  PluginProps,
  useLightboxProps,
  useLightboxState,
  Callbacks,
} from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Button, Center, Modal, Text, ThemeIcon } from "@mantine/core";
import { UploadImageModal } from "containers/UI/Modals/UploadImageModal";
import { useTranslation } from "react-i18next";
import { ImageTarget } from "hooks/useImageUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { RemoveImageLightboxModal } from "containers/UI/Modals/RemoveImageLightboxModal";

type LightboxGalleryProps = {
  index?: number;
  insideLinks?: boolean;
  images: string[];
  isOpen: boolean;
  on?: Callbacks;
  onClose: () => void;
  target?: ImageTarget;
};

declare module "yet-another-react-lightbox" {
  interface Labels {
    Upload?: string;
    Remove?: string;
  }
  interface LightboxProps {
    onRemoveButtonClick?: (link: string) => void;
    onUploadButtonClick?: () => void;
  }
}

const RemoveIcon = createIcon(
  "RemoveIcon",
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="19"
    height="19"
  >
    <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
  </svg>
);

function RemoveButton() {
  const { onRemoveButtonClick } = useLightboxProps();
  const { currentSlide } = useLightboxState();

  return (
    <IconButton
      label="Remove"
      icon={RemoveIcon}
      disabled={!currentSlide}
      onClick={() => {
        if (onRemoveButtonClick && currentSlide) {
          onRemoveButtonClick(currentSlide.src);
        }
      }}
    />
  );
}

function Remove({ augment }: PluginProps) {
  augment(({ toolbar, onRemoveButtonClick, ...restProps }) => ({
    toolbar: addToolbarButton(toolbar, "remove", <RemoveButton />),
    onRemoveButtonClick,
    ...restProps,
  }));
}

const UploadIcon = createIcon(
  "UploadIcon",
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="19"
    height="19"
  >
    <path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
  </svg>
);

function UploadButton() {
  const { onUploadButtonClick } = useLightboxProps();

  return (
    <IconButton
      label="Upload"
      icon={UploadIcon}
      onClick={() => {
        if (onUploadButtonClick) {
          onUploadButtonClick();
        }
      }}
    />
  );
}

function Upload({ augment }: PluginProps) {
  augment(({ toolbar, onUploadButtonClick, ...restProps }) => ({
    toolbar: addToolbarButton(toolbar, "upload", <UploadButton />),
    onUploadButtonClick,
    ...restProps,
  }));
}

function ErrorContent({
  onClose,
  text,
  type,
}: {
  onClose: () => void;
  text?: string;
  type: "remove" | "upload";
}) {
  const { t } = useTranslation();

  return (
    <>
      <Center mb="xs">
        <ThemeIcon
          size={60}
          variant="light"
          color="red"
          radius={100}
          sx={{ fontSize: 30 }}
        >
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </ThemeIcon>
      </Center>
      <Text size="xl" weight={700} mb="xs" align="center">
        {t("generic.error_title")}
      </Text>
      <Text size="sm" align="center" mb="sm">
        {text !== undefined
          ? text
          : type === "upload"
          ? t("image_upload_modal.upload_failure_text")
          : t("remove_image_modal.remove_failure_text")}
      </Text>
      <Button
        color="red"
        fullWidth
        onClick={() => {
          onClose();
        }}
      >
        {t("generic.dismiss")}
      </Button>
    </>
  );
}

function SuccessContent({
  onClose,
  type,
}: {
  onClose: () => void;
  type: "remove" | "upload";
}) {
  const { t } = useTranslation();

  return (
    <>
      <Center mb="xs">
        <ThemeIcon
          size={60}
          variant="light"
          color="violet"
          radius={100}
          sx={{ fontSize: 30 }}
        >
          <FontAwesomeIcon icon={faInfo} />
        </ThemeIcon>
      </Center>
      <Text size="xl" weight={700} mb="xs" align="center">
        {type === "upload"
          ? t("image_upload_modal.upload_success_title")
          : t("remove_image_modal.remove_success_title")}
      </Text>
      <Text size="sm" align="center" mb="sm">
        {type === "upload"
          ? t("image_upload_modal.upload_success_text")
          : t("remove_image_modal.remove_success_text")}
      </Text>
      <Button
        color="primaryColor"
        fullWidth
        onClick={() => {
          onClose();
        }}
      >
        {t("generic.ok")}
      </Button>
    </>
  );
}

export const LightboxGallery = ({
  index,
  insideLinks = false,
  images,
  isOpen,
  on,
  onClose,
  target,
}: LightboxGalleryProps) => {
  const { t } = useTranslation();
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [statusContent, setStatusContent] = useState<JSX.Element>();

  const handleModalClose = useCallback(
    (type: "upload" | "remove") => {
      if (type === "upload") {
        setUploadModalOpen(false);
      } else {
        setRemoveModalOpen(false);
      }
      // Since React batches state updates we need to delay this
      setTimeout(() => setStatusContent(undefined), 500);
    },
    [setStatusContent, setUploadModalOpen]
  );

  const toggleRemoveModalOpen = useCallback(() => {
    return () => setRemoveModalOpen((state) => !state);
  }, [setRemoveModalOpen]);

  const toggleUploadModalOpen = useCallback(() => {
    return () => setUploadModalOpen((state) => !state);
  }, [setUploadModalOpen]);

  const getAnchorElement = (data: string) => (
    <a
      href={data}
      target="_blank"
      rel="noreferrer"
      style={{ display: "block", textAlign: "center", color: "white" }}
    >
      {data}
    </a>
  );

  const formatImages = (data: string[]) =>
    data.map((item, index) => ({
      src: item,
      title: `${data.length - index}/${data.length}`,
      description: getAnchorElement(item),
    }));

  return (
    <Lightbox
      index={index}
      on={on}
      open={isOpen}
      close={onClose}
      onRemoveButtonClick={toggleRemoveModalOpen()}
      onUploadButtonClick={toggleUploadModalOpen()}
      slides={formatImages(images).reverse()}
      plugins={[Captions, Remove, Thumbnails, Upload]}
      render={{
        controls: () => (
          <>
            <Modal
              withCloseButton={statusContent !== undefined ? false : true}
              centered
              opened={removeModalOpen}
              onClose={toggleRemoveModalOpen()}
              closeOnClickOutside
              size={statusContent !== undefined ? "xs" : "md"}
              title={
                statusContent !== undefined
                  ? null
                  : t("remove_image_modal.title")
              }
              withinPortal={false}
              styles={{
                inner: { inset: 0 },
                title: {
                  fontWeight: "bold",
                  fontSize: "1.125rem",
                },
              }}
            >
              {statusContent !== undefined ? (
                statusContent
              ) : (
                <RemoveImageLightboxModal
                  extraMessage={
                    insideLinks
                      ? t("remove_image_modal.extra_message_links_editor")
                      : undefined
                  }
                  onError={(message) => {
                    setStatusContent(
                      <ErrorContent
                        text={message}
                        type="remove"
                        onClose={() => {
                          handleModalClose("remove");
                        }}
                      />
                    );
                  }}
                  onSuccess={() => {
                    setStatusContent(
                      <SuccessContent
                        type="remove"
                        onClose={() => {
                          handleModalClose("remove");
                        }}
                      />
                    );
                  }}
                  onClose={toggleRemoveModalOpen()}
                  target={target}
                />
              )}
            </Modal>
            <Modal
              withCloseButton={statusContent !== undefined ? false : true}
              centered
              opened={uploadModalOpen}
              onClose={toggleUploadModalOpen()}
              closeOnClickOutside
              title={
                statusContent !== undefined
                  ? null
                  : t("lightbox_gallery_modal.title")
              }
              withinPortal={false}
              styles={{
                inner: { inset: 0 },
                title: {
                  fontWeight: "bold",
                  fontSize: "1.125rem",
                },
              }}
            >
              {statusContent !== undefined ? (
                statusContent
              ) : (
                <UploadImageModal
                  extraMessage={
                    insideLinks
                      ? t("image_upload_modal.extra_message_links_editor")
                      : undefined
                  }
                  onError={() => {
                    setStatusContent(
                      <ErrorContent
                        type="upload"
                        onClose={() => {
                          handleModalClose("upload");
                        }}
                      />
                    );
                  }}
                  onSuccess={() => {
                    setStatusContent(
                      <SuccessContent
                        type="upload"
                        onClose={() => {
                          handleModalClose("upload");
                        }}
                      />
                    );
                  }}
                  target={target}
                />
              )}
            </Modal>
          </>
        ),
      }}
    />
  );
};
