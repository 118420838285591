import {
  faCircleHalfStroke,
  faExpand,
  faLanguage,
  faNewspaper,
  faScaleUnbalanced,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Group,
  NavLink,
  NavLinkProps,
  Stack,
  Switch,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { Unit } from "config/enums/units";
import { useAuth } from "hooks/useAuth";
import { useGenericModals } from "hooks/useGenericModals";
import { useTranslation } from "react-i18next";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { getErrorMsg } from "utils/getErrorMsg/getErrorMsg";

import { LanguageSelect } from "../LeftMenu/MenuItems/LanguageSelect";
import { ProgressBar } from "components/UI/ProgressBar";

const NavRow = (props: NavLinkProps) => {
  const theme = useMantineTheme();
  return (
    <NavLink
      styles={{
        root: {
          cursor: "default",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        icon: { color: theme.colors.gray[6], width: 20 },
        rightSection: { marginLeft: "0.5em" },
      }}
      {...props}
    />
  );
};

export const SettingsModal = ({ context, id }: ContextModalProps) => {
  const { t } = useTranslation();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const {
    state: { units, autoSaveView, receiveNewsletter, storageSize },
    updateSaveable,
  } = useOptions();
  const { openConfirmModal, openErrorModal } = useGenericModals();
  const { deleteUser } = useAuth();

  const handleDeleteUser = async () => {
    try {
      await deleteUser();
    } catch (error) {
      const message = getErrorMsg(error);
      openErrorModal({
        title: t("generic.error_title"),
        text: message || t("errors.cannot_delete_account"),
      });
    }
    context.closeAll();
  };

  const handleDeleteAccount = () => {
    openConfirmModal({
      title: t("delete_account_modal.title"),
      text: t("delete_account_modal.text"),
      labels: {
        confirm: t("delete_account_modal.confirm_button_label"),
        cancel: t("delete_account_modal.cancel_button_label"),
      },
      onConfirm: handleDeleteUser,
    });
  };

  return (
    <Stack>
      <div>
        <NavRow
          label={<LanguageSelect />}
          icon={<FontAwesomeIcon icon={faLanguage} />}
        />
        <NavRow
          label={t("account_settings_modal.dark_mode_label")}
          icon={<FontAwesomeIcon icon={faCircleHalfStroke} />}
          rightSection={
            <Switch
              styles={{
                input: { cursor: "pointer" },
              }}
              onLabel="ON"
              offLabel="OFF"
              size="md"
              checked={colorScheme === "dark"}
              onChange={(event) => {
                toggleColorScheme();
                localStorage.setItem(
                  "colorScheme",
                  event.currentTarget.checked ? "dark" : "light"
                );
                updateSaveable({
                  colorScheme: event.currentTarget.checked ? "dark" : "light",
                });
              }}
            />
          }
        />
        <NavRow
          label={t("account_settings_modal.imperial_units_label")}
          icon={<FontAwesomeIcon icon={faScaleUnbalanced} />}
          rightSection={
            <Switch
              styles={{
                input: { cursor: "pointer" },
              }}
              size="md"
              onLabel="ON"
              offLabel="OFF"
              checked={units === Unit.imperial}
              onChange={(event) => {
                updateSaveable({
                  units: event.currentTarget.checked
                    ? Unit.imperial
                    : Unit.metric,
                });
              }}
            />
          }
        />
        <NavRow
          label={t("account_settings_modal.view_autosaving_label")}
          icon={<FontAwesomeIcon icon={faExpand} />}
          rightSection={
            <Switch
              styles={{
                input: { cursor: "pointer" },
              }}
              size="md"
              onLabel="ON"
              offLabel="OFF"
              checked={autoSaveView}
              onChange={(event) => {
                updateSaveable({ autoSaveView: event.currentTarget.checked });
              }}
            />
          }
        />
        <NavRow
          label={t("account_settings_modal.newsletter_label")}
          icon={<FontAwesomeIcon icon={faNewspaper} />}
          rightSection={
            <Switch
              styles={{
                input: { cursor: "pointer" },
              }}
              size="md"
              onLabel="ON"
              offLabel="OFF"
              checked={receiveNewsletter}
              onChange={(event) => {
                updateSaveable({
                  receiveNewsletter: event.currentTarget.checked,
                });
              }}
            />
          }
        />
        <div style={{ padding: "15px" }}>
          <ProgressBar value={storageSize?.percentage} />
          <Text size="xs" align="end" style={{ marginTop: "5px" }}>
            {t("account_settings_modal.used_storage_space")}:{" "}
            {storageSize
              ? storageSize.storageUsed + " / " + storageSize.storageTotal + " MB"
              : t("image_upload_modal.unknown_used_space")}
          </Text>
        </div>
      </div>
      <Group sx={{ justifyContent: "space-between" }}>
        <Button onClick={handleDeleteAccount} color="red">
          {t("account_settings_modal.delete_button_label")}
        </Button>
        <Button onClick={() => context.closeModal(id)}>
          {t("generic.ok")}
        </Button>
      </Group>
    </Stack>
  );
};
