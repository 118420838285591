import { escape } from "lodash";
import { Coords } from "types/app";

import { wrap } from "./encodeHTML";

export const encodeTracksToGPX = (
  trackPoints: Coords[],
  trackPointsEle: number[],
  name: string,
  description?: string,
  links?: string[]
) => {
  if (trackPoints.length !== trackPointsEle.length)
    throw new Error(
      "Error encoding track to GPX. Trackpoints.length !== TrackpointsElevations.length"
    );

  const gpxTrackData = ["<trk>"];
  if (name) gpxTrackData.push(`    <name>${wrap(name)}</name>`);
  if (description) gpxTrackData.push(`    <desc>${wrap(description)}</desc>`);
  if (links) {
    links.forEach((link) => {
      const escapedLink = escape(link);
      gpxTrackData.push(`    <link href="${escapedLink}"></link>`);
    });
  }
  gpxTrackData.push("    <trkseg>");
  trackPoints.forEach((trackpoint, index) => {
    gpxTrackData.push(
      `      <trkpt lat="${trackpoint[0]}" lon="${
        trackpoint[1]
      }"><ele>${trackPointsEle[index]!.toFixed(4)}</ele></trkpt>`
    );
  });
  gpxTrackData.push("    </trkseg>");
  gpxTrackData.push("  </trk>");

  return gpxTrackData.join("\n");
};
