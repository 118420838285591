import {
  faImage,
  faImages,
  faGlobe,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { LightboxGallery } from "./Gallery/LightboxGallery";
import { ImageTarget } from "hooks/useImageUpload";
import { UploadImageModal } from "containers/UI/Modals/UploadImageModal";
import { useGenericModals } from "hooks/useGenericModals";
import { RemoveImageModal } from "containers/UI/Modals/RemoveImageModal";

type LinksEditorProps = {
  onEdit: (links: string[], callback?: () => void) => void;
  links: string[];
  disabled?: boolean;
  target: ImageTarget;
};

interface LinkIconProps {
  disabled?: boolean;
  isTrackbookImageLink: boolean;
  link?: string;
  onClick?: () => void;
}

function LinkIcon(props: LinkIconProps) {
  const { disabled = false, isTrackbookImageLink, link, onClick } = props;

  return isTrackbookImageLink ? (
    <ActionIcon
      color="primaryColor"
      disabled={disabled}
      onClick={onClick}
      tabIndex={1}
      variant="filled"
    >
      <FontAwesomeIcon icon={faImages} fontSize={14} />
    </ActionIcon>
  ) : (
    <a href={link} target="blank">
      <ActionIcon
        tabIndex={1}
        variant="filled"
        color="primaryColor"
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faGlobe} fontSize={14} />
      </ActionIcon>
    </a>
  );
}

function fixLink(link: string) {
  if (
    !link.startsWith("file:///") &&
    !link.startsWith("http://") &&
    !link.startsWith("https://")
  ) {
    return `//${link}`;
  }
  return link;
}

function Links({ disabled, links, onEdit, target }: LinksEditorProps) {
  const { t } = useTranslation();
  const { openInfoModal, openErrorModal } = useGenericModals();
  const trackbookImages: string[] = [];
  const [slideIndex, setSlideIndex] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [removeImageModalOpen, setRemoveImageModalOpen] = useState<{
    open: boolean;
    link: string | undefined;
  }>({ open: false, link: undefined });

  const handleEdit = useCallback(
    (index: number, value: string) => {
      const updatedLinks = [...links];
      updatedLinks[index] = value;
      onEdit(updatedLinks);
    },
    [links, onEdit]
  );

  const deleteLink = useCallback(
    (linkIndex: number) => {
      const updatedLinks = links.filter((_, i) => i !== linkIndex);
      onEdit(updatedLinks);
    },
    [links, onEdit]
  );

  const handleIconClick = useCallback((index) => {
    return () => {
      setSlideIndex(index);
      setTimeout(() => {
        setGalleryOpen(true);
      }, 200);
    };
  }, []);

  const toggleRemoveImageModalOpen = useCallback(
    (link?: string) => {
      setRemoveImageModalOpen((value) => ({ open: !value.open, link }));
    },
    [setRemoveImageModalOpen]
  );

  return (
    <>
      {links.map((link, index) => {
        const fixedLink = fixLink(link);
        const isTrackbookImageLink =
          link.includes("trackbook") &&
          new RegExp(/\.(png|jpe?g|gif)$/).test(link);

        if (isTrackbookImageLink) {
          trackbookImages.push(link);
        }

        return (
          <Group key={index} spacing="xs">
            <TextInput
              sx={{ flexGrow: 1 }}
              value={link}
              onChange={(e) => handleEdit(index, e.currentTarget.value)}
              data-lpignore="true"
              placeholder="https://example.com"
              disabled={disabled || isTrackbookImageLink}
            />
            <LinkIcon
              link={fixedLink}
              disabled={disabled}
              onClick={handleIconClick(trackbookImages.length - 1)}
              isTrackbookImageLink={isTrackbookImageLink}
            />
            <ActionIcon
              tabIndex={1}
              variant="filled"
              color="red"
              onClick={() => {
                if (isTrackbookImageLink) {
                  toggleRemoveImageModalOpen(link);
                } else {
                  deleteLink(index);
                }
              }}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrashAlt} fontSize={14} />
            </ActionIcon>
          </Group>
        );
      })}
      <Modal
        withCloseButton
        centered
        opened={removeImageModalOpen.open}
        onClose={toggleRemoveImageModalOpen}
        closeOnClickOutside
        size={"md"}
        title={t("remove_image_modal.title")}
        withinPortal={false}
        styles={{
          inner: { inset: 0 },
          title: {
            fontWeight: "bold",
            fontSize: "1.125rem",
          },
        }}
      >
        <RemoveImageModal
          // Will always be defined if the modal is open
          link={removeImageModalOpen.link as string}
          extraMessage={t("remove_image_modal.extra_message_links_editor")}
          onError={(message) => {
            openErrorModal({
              title: t("generic.error_title"),
              text: message ?? t("remove_image_modal.remove_failure_text"),
            });
          }}
          onSuccess={() => {
            openInfoModal(
              t("remove_image_modal.remove_success_title"),
              t("remove_image_modal.remove_success_text")
            );
          }}
          onClose={toggleRemoveImageModalOpen}
          target={target}
        />
      </Modal>
      <LightboxGallery
        index={slideIndex}
        on={{ view: ({ index: currentIndex }) => setSlideIndex(currentIndex) }}
        insideLinks
        target={target}
        isOpen={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        images={trackbookImages}
      />
    </>
  );
}

export const LinksEditor = ({
  onEdit,
  links,
  disabled = false,
  target,
}: LinksEditorProps) => {
  const { t } = useTranslation();
  const { openErrorModal, openInfoModal } = useGenericModals();
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);

  const addEmptyLink = useCallback(() => {
    onEdit([...links, ""]);
  }, [links, onEdit]);

  const toggleImageUploadModalOpen = useCallback(() => {
    setImageUploadModalOpen((value) => !value);
  }, [setImageUploadModalOpen]);

  return (
    <div>
      <Group mb={4} spacing={6}>
        <Text size="sm">{t("links_editor.label")}</Text>
        <ActionIcon
          size="xs"
          color="primaryColor"
          variant="filled"
          radius={30}
          onClick={addEmptyLink}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlus} fontSize={14} />
        </ActionIcon>
        <ActionIcon
          size="xs"
          color="primaryColor"
          variant="filled"
          radius={30}
          onClick={toggleImageUploadModalOpen}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faImage} fontSize={12} />
        </ActionIcon>
      </Group>
      <Stack>
        <Links links={links} onEdit={onEdit} target={target} />
      </Stack>
      <Modal
        centered
        opened={imageUploadModalOpen}
        onClose={toggleImageUploadModalOpen}
        closeOnClickOutside
        title={t("lightbox_gallery_modal.title")}
        styles={{ title: { fontWeight: "bold", fontSize: "1.125rem" } }}
        style={{ zIndex: 400 }}
      >
        <UploadImageModal
          target={target}
          extraMessage={t('image_upload_modal.extra_message_links_editor')}
          onClose={toggleImageUploadModalOpen}
          onError={() => {
            toggleImageUploadModalOpen();
            openErrorModal({
              title: t("generic.error_title"),
              text: t("image_upload_modal.upload_failure_text"),
            });
          }}
          onSuccess={() => {
            toggleImageUploadModalOpen();
            openInfoModal(
              t("image_upload_modal.upload_success_title"),
              t("image_upload_modal.upload_success_text")
            );
          }}
        />
      </Modal>
    </div>
  );
};
