import { useCallback, useState } from "react";

import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mantine/core";
import { LightboxGallery } from "components/UI/Gallery/LightboxGallery";
import { Tooltip } from "components/UI/Tooltip";
import { useUserTracks } from "hooks/useUserTracks";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { useTranslation } from "react-i18next";
import { CollapsibleTopMenuActionIcon } from "../../../../components/UI/TopMenu/CollapsibleActionIcon";
import { UploadImageModal } from "containers/UI/Modals/UploadImageModal";
import { ImageTarget } from "hooks/useImageUpload";
import { useGenericModals } from "hooks/useGenericModals";

type GalleryIconProps = {
  disabled?: boolean;
  target?: ImageTarget;
};

export const GalleryIcon = ({ disabled = false, target }: GalleryIconProps) => {
  const { t } = useTranslation();
  const { selectedWaypoint } = useUserWaypoints();
  const { selectedTrack } = useUserTracks();
  const { openInfoModal, openErrorModal } = useGenericModals();
  const [open, setOpen] = useState(false);

  const targetEntity = target === "track" ? selectedTrack : selectedWaypoint;

  const imageLinks =
    targetEntity?.links && targetEntity?.links.length > 0
      ? targetEntity.links.filter(
          (link) =>
            link.includes("trackbook") &&
            new RegExp(/\.(png|jpe?g|gif)$/).test(link)
        )
      : [];

  const openGallery = useCallback(() => {
    // This also opens the upload modal in case there are no images - I'm respecting the original design
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Tooltip label={t("editmode.open_gallery_button_tooltip")}>
        <div>
          <CollapsibleTopMenuActionIcon
            isOpen={target !== undefined}
            onClick={openGallery}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faImage} />
          </CollapsibleTopMenuActionIcon>
        </div>
      </Tooltip>
      {open ? (
        imageLinks.length > 0 ? (
          <LightboxGallery
            images={imageLinks}
            isOpen
            onClose={handleClose}
            target={target}
          />
        ) : (
          <Modal
            centered
            opened
            onClose={handleClose}
            closeOnClickOutside
            title={t("lightbox_gallery_modal.title")}
            styles={{ title: { fontWeight: "bold", fontSize: "1.125rem" } }}
            style={{ zIndex: 400 }}
          >
            <UploadImageModal
              target={target}
              onClose={handleClose}
              onError={() => {
                handleClose();
                openErrorModal({
                  title: t("generic.error_title"),
                  text: t("image_upload_modal.upload_failure_text"),
                });
              }}
              onSuccess={() => {
                handleClose();
                openInfoModal(
                  t("image_upload_modal.upload_success_title"),
                  t("image_upload_modal.upload_success_text")
                );
              }}
            />
          </Modal>
        )
      ) : null}
    </>
  );
};
