import { useCallback, useMemo } from "react";

import { MAP_TYPE_PREVIEWS } from "assets/mapTypePreviews";
import { MapLayerPickerPart } from "components/LeafletMap/MapControls/MapLayerPicker";
import { RASTER_LAYERS_LABELS } from "components/LeafletMap/RasterTileLayers";
import { TANGRAM_LAYERS_LABELS } from "components/LeafletMap/TangramTileLayer";
import { MapID, premiumMapIds } from "config/enums/mapIDs";
import { useImagePreload } from "hooks/useImagePreload";
import { useTranslation } from "react-i18next";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { PremiumFeatureWrapper } from "containers/UI/Wrappers/PremiumFeatureWrapper";
import { DEFAULT_MAP_ID } from "config/constants";

const MAP_TYPES = [...TANGRAM_LAYERS_LABELS, ...RASTER_LAYERS_LABELS];

const MapLayerCardWrapper = (
  props: React.PropsWithChildren<{ mapId: number }>
) => {
  const {t} = useTranslation();
  const { children, mapId } = props;

  if (premiumMapIds.has(mapId)) {
    return (
      <PremiumFeatureWrapper
        // Currently, the premium feature names are bolded with Markdown, so we need to remove the asterisks
        featureName={t(premiumMapIds.get(mapId) as string).replaceAll('*', '')}
        hasTooltip={false}
        lockIconStyles={{
          color: "#6741d9",
          top: "20%",
          left: "40%",
          fontSize: "32px",
        }}
      >
        {children}
      </PremiumFeatureWrapper>
    );
  }
  return <>{children}</>;
};

export const MapTypePicker = () => {
  useImagePreload(Object.values(MAP_TYPE_PREVIEWS));
  const { t } = useTranslation();
  const {
    state: { allowPremiumFeatures, mapType },
    updateSaveable,
  } = useOptions();
  const handleTypeSelect = useCallback(
    (value: MapID) => {
      updateSaveable({ mapType: value });
    },
    [updateSaveable]
  );

  const selectedMap = useMemo(
    () =>
      premiumMapIds.has(mapType)
        ? allowPremiumFeatures
          ? mapType
          : DEFAULT_MAP_ID
        : mapType,
    [allowPremiumFeatures, mapType]
  );

  return (
    <MapLayerPickerPart title={t("map_layer_picker.map_type_heading")}>
      <MapLayerPickerPart.Grid>
        {MAP_TYPES.map((type, i) => {
          const mapId = type.value as MapID;
          const imageSrc = MAP_TYPE_PREVIEWS[mapId];

          return (
            <MapLayerCardWrapper key={i} mapId={mapId}>
              <MapLayerPickerPart.Card
                label={type.label}
                value={mapId}
                imageSrc={imageSrc}
                onClick={handleTypeSelect}
                selected={mapId === selectedMap}
              />
            </MapLayerCardWrapper>
          );
        })}
      </MapLayerPickerPart.Grid>
    </MapLayerPickerPart>
  );
};
