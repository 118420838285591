import React from "react";
import "./ProgressBar.css";

interface ProgressBarProps {
  /*
   * A number between 0 and 100 (ideally)
   */
  value?: number;
}

function normalizeValue(value: number) {
  if (value < 0) {
    return 0;
  } else if (value > 100) {
    return 100;
  } else {
    return value;
  }
}

export function ProgressBar(props: ProgressBarProps) {
  const { value = 0 } = props;

  return (
    <div id="progress-bar-container">
      <div
        id="progress-bar"
        style={{
          backgroundColor: normalizeValue(value) < 100 ? "#6741d9" : "#e03131",
          width: `${value}%`,
        }}
      ></div>
    </div>
  );
}
