import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { Paper, Stack } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { LeftToggle } from 'components/UI/Toggles/LeftToggle'
import { PANEL_TOGGLE_ANIMATION_DURATION } from 'config/constants'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'

export const LeftMenuWrapper = ({ children }: { children: ReactNode }) => {
  const {
    state: { isLeftMenuOpen },
    dispatch,
  } = useOptions()
  const isSmallViewport = useMediaQuery('(max-width: 768px)')
  const [initialLoad, setInitialLoad] = useState<boolean | null>(null)

  // We can only get info about whether viewport is a small viewport after the first render
  // so we need to check for that here and then reload so we are able to run this useEffect again
  // with a correct (updated) value of isSmallViewport
  useEffect(() => {
    if (initialLoad === null) {
      setInitialLoad(true)
    } else if (initialLoad === true) {
      setInitialLoad(false)
      // On small devices (<=768px in width) this left menu should be initially closed
      // It's closed by default (because with the animation it has, it looked better to open it)
      if (isLeftMenuOpen === isSmallViewport) {
        dispatch(updateNonSaveableOptions({ isLeftMenuOpen: !isSmallViewport }))
      }
    }
  }, [dispatch, isLeftMenuOpen, isSmallViewport, initialLoad])

  const handleToggle = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      dispatch(updateNonSaveableOptions({ isLeftMenuOpen: !isLeftMenuOpen }))
    },
    [dispatch, isLeftMenuOpen]
  )

  const width = useMemo(() => (isSmallViewport ? 260 : 340), [isSmallViewport])

  return (
    <Stack
      spacing={4}
      sx={{
        // On small devices (<=768px in width) this left menu should be floating above the map
        position: isSmallViewport ? 'absolute' : 'relative',
        width,
        height: '100%',
        marginLeft: isLeftMenuOpen ? 0 : -width,
        transition: `margin ${PANEL_TOGGLE_ANIMATION_DURATION}s`,
        flexShrink: 0,
        zIndex: isSmallViewport ? 200 : 100,
      }}
    >
      <LeftToggle isOpen={isLeftMenuOpen} handleToggle={handleToggle} />

      <Paper
        shadow="lg"
        radius={0}
        sx={{ height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column', zIndex: 10 }}
      >
        {children}
      </Paper>
    </Stack>
  )
}
