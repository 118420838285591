import { forwardRef, useMemo } from "react";

import { Group, Image, Select, Text } from "@mantine/core";
import { LANGUAGES } from "config/enums/languages";
import { useLanguage } from "hooks/useLanguage";
import { useTranslation } from "react-i18next";

const getCurrentLanguageMeta = (language: string) =>
  LANGUAGES.find((lang) => lang.value === language);

const IconComponent = ({ language }: { language: string }) => {
  const src = getCurrentLanguageMeta(language);
  return <Image withPlaceholder src={src?.flagSrc} width={20} />;
};

interface SelectItemProps extends React.ComponentPropsWithoutRef<"div"> {
  flagSrc: string;
  label: string;
  value: string;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  (
    { flagSrc, label, onMouseEnter, onMouseDown, ...rest }: SelectItemProps,
    ref
  ) => {
    const memoizedOnMouseDown = useMemo(() => onMouseDown, []);
    const memoizedOnMouseEnter = useMemo(() => onMouseEnter, []);

    return (
      <div
        ref={ref}
        onMouseDown={memoizedOnMouseDown}
        onMouseEnter={memoizedOnMouseEnter}
        {...rest}
      >
        <Group noWrap spacing="xs">
          <Image src={flagSrc} withPlaceholder width={20} />
          <Text size="sm">{label}</Text>
        </Group>
      </div>
    );
  }
);

SelectItem.displayName = "SelectItem";

export const LanguageSelect = () => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  let languageShort = language.replace("-", "_");
  const regionSeparatorIdx = languageShort.indexOf("_");

  if (regionSeparatorIdx !== -1) {
    languageShort =
      languageShort && languageShort.toLowerCase() !== "pt_br"
        ? languageShort.substring(0, regionSeparatorIdx)
        : languageShort;
  }

  return (
    <Group position="apart">
      <Text>{t("account_settings_modal.language_label")}</Text>
      <Select
        size="xs"
        data={LANGUAGES}
        icon={<IconComponent language={languageShort} />}
        // Right now we only use the 'en' part of the 'en-US' language (example) to show a flag
        value={languageShort}
        style={{ maxWidth: 200 }}
        onChange={changeLanguage}
        itemComponent={SelectItem}
      />
    </Group>
  );
};
