import { useCallback, useMemo } from "react";

import {
  ScrollArea,
  Space,
  Title,
  Text,
  Anchor,
  Image,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useTrackStats } from "hooks/useTrackStats";
import { useTranslation } from "react-i18next";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { useRouting } from "stores/routingStore/RoutingContext";

import logo_dark from "../../../assets/main/trackbook_icon_dark.png";
import logo_light from "../../../assets/main/trackbook_icon_light.png";
import { TrackStatsDisplay } from "../../../components/UI/OptionsPanel/TrackStatsDisplay";
import { RoutingPicker } from "./RoutingPicker";
import { TrackpointsList } from "./TrackpointsDisplay";

import "./PlanMenu.css";
import { useMediaQuery } from "usehooks-ts";
import { useAuth } from "hooks/useAuth";
import { useModals } from "@mantine/modals";

const styles = {
  div: {
    display: "flex",
    alignItems: "center",
    gap: ".4rem",
    textDecoration: "none",
    marginTop: "auto",
    padding: "0 10px 10px 10px",
    width: "max-content",
  },
  image: { width: "1.7rem" },
  title: { fontSize: "1.1rem", position: "relative" as const, top: "-1.5px" },
};

export const PlanMenu = () => {
  const { stats } = useTrackStats();
  const { t } = useTranslation();
  const {
    state: { trackMeta },
  } = useRouting();
  const {
    state: { allowPremiumFeatures, units },
  } = useOptions();
  const { user } = useAuth();
  const modals = useModals();
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const isSmallViewport = useMediaQuery("(max-width: 768px)");

  const handleOnlineServicesClick = useCallback(() => {
    modals.openContextModal("premiumFeatureModal", {
      title: <Text>{`${t("subscription_modal.title")} 🚡`}</Text>,
      innerProps: {},
      padding: 0,
      styles: {
        header: { padding: 20, marginBottom: 0 },
      },
      size: "xl",
    });
  }, [modals]);

  return useMemo(() => {
    const activeColor = colorScheme === "light" ? "#00a800" : "#00d000";
    const inactiveColor = colorScheme === "light" ? "red" : "#fa5252";
    const onlineServicesTextColor = allowPremiumFeatures
      ? activeColor
      : inactiveColor;

    const onlineServicesInfo =
      user !== null ? (
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginRight: "5px",
            paddingBottom: isSmallViewport ? "5px" : "13px",
            alignContent: "center",
            cursor: !allowPremiumFeatures ? "pointer" : "text",
          }}
          onClick={!allowPremiumFeatures ? handleOnlineServicesClick : undefined}
        >
          <Text
            size="xs"
            style={{ display: "inline-block", marginRight: "5px" }}
          >
            Online services:
          </Text>
          <Text
            size="xs"
            style={{
              display: "inline-block",
              color: onlineServicesTextColor,
            }}
          >
            {allowPremiumFeatures
              ? t("online_services.side_panel_info_text_active_status")
              : t("online_services.side_panel_info_text_inactive_status")}
          </Text>
        </div>
      ) : null;

    return (
      <>
        <ScrollArea
          style={{ flexGrow: 1, padding: "10px 10px 0 10px" }}
          styles={{
            thumb: {
              "&:before": { minWidth: 0 },
            },
            viewport: {
              "& > div": {
                display: "flex!important",
                flexDirection: "column",
                height: "100%",
              },
            },
          }}
        >
          <RoutingPicker />
          <Space h="md" />
          <TrackpointsList />
          <Space h="lg" />
          <Text px="xs" py={4} color="dimmed">
            {t("plan_menu.stats_label")}
          </Text>
          <TrackStatsDisplay
            stats={{ ...stats, totalTime: trackMeta.totalTime }}
            unitType={units}
          />
        </ScrollArea>
        <div
          style={{
            paddingTop: "5px",
            display: "flex",
            flexDirection: isSmallViewport ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          {isSmallViewport ? onlineServicesInfo : null}
          <Anchor href="/" style={styles.div}>
            <Image
              style={styles.image}
              radius="md"
              src={colorScheme === "light" ? logo_light : logo_dark}
              alt="Trackbook logo"
            />
            <Title
              order={1}
              style={styles.title}
              weight="600"
              color={colorScheme === "light" ? theme.primaryColor : "#a68cf4"}
            >
              {"Trackbook"}
            </Title>
          </Anchor>
          {!isSmallViewport ? onlineServicesInfo : null}
        </div>
      </>
    );
  }, [
    handleOnlineServicesClick,
    isSmallViewport,
    stats,
    t,
    trackMeta.totalTime,
    units,
    user,
    colorScheme,
    theme,
  ]);
};
