import React from "react";

import { Box, Group, Stack } from "@mantine/core";
import { useAuth } from "hooks/useAuth";
import { useInitialModals } from "hooks/useInitialModals";

import { ChartPanel } from "./UI/ChartPanel/ChartPanel";
import { AuthenticatedUserMenu } from "./UI/LeftMenu/AuthenticatedUserMenu";
import { UnauthenticatedUserMenu } from "./UI/LeftMenu/Unauthenticated/UnauthenticatedUserMenu";
import { LegendPanel } from "./UI/LegendPanel/LegendPanel";
import { TopEditMenu } from "./UI/TopEditMenu/TopEditMenu";
import { LeftMenuWrapper } from "./UI/Wrappers/LeftMenuWrapper";
import { MapWrapper } from "./UI/Wrappers/MapWrapper";

export const MainContainer = () => {
  const { user } = useAuth();

  useInitialModals(user);

  return (
    <Box sx={{ width: "100%", height: "100%", position: "absolute" }}>
      <Group
        sx={{
          height: "100%",
          width: "100%",
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
        spacing={0}
      >
        <LeftMenuWrapper>
          {user ? <AuthenticatedUserMenu /> : <UnauthenticatedUserMenu />}
          {/* <TabMenu /> */}
        </LeftMenuWrapper>

        <Stack
          id="mapchartwrapper"
          sx={{
            height: "100%",
            flexGrow: 1,
            minWidth: 0,
            overflow: "hidden",
            position: "relative",
          }}
          spacing={0}
        >
          <MapWrapper />
          <ChartPanel />
          <TopEditMenu />
        </Stack>
        <LegendPanel />
        {/* {isDev() && <DebugPanel />} */}
      </Group>
    </Box>
  );
};
