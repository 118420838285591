import { escape } from "lodash";
import { WaypointSave } from "utils/helpers/waypointParser/waypointParser";
import { decodePolyline } from "utils/polyline";

import { wrap } from "./encodeHTML";

export const encodeWaypointsToGPX = (waypoints: WaypointSave[]) => {
  const result: string[] = [];
  waypoints.forEach((waypoint) => {
    const point = decodePolyline(waypoint.point);
    if (!point || !point[0]) throw new Error("Error encoding waypoints to GPX");

    const [latStr, lngStr] = point[0];

    result.push(`  <wpt lat="${latStr}" lon="${lngStr}">`);

    if (waypoint.name) result.push(`    <name>${wrap(waypoint.name)}</name>`);
    if (waypoint.desc) result.push(`    <desc>${wrap(waypoint.desc)}</desc>`);
    if (waypoint.icon) result.push(`    <sym>${waypoint.icon}</sym>`);
    if (waypoint.links) {
      const links = waypoint.links.split(",");
      links.forEach((link) => {
        const escapedLink = escape(link);
        result.push(`    <link href="${escapedLink}"></link>`);
      });
    }

    result.push("  </wpt>");
  });

  return result.join("\n");
};
