import { useCallback } from "react";
import {
  Button,
  Image,
  ScrollArea,
  Stack,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import { ContextModalProps, useModals } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { landscapes } from "assets/subscriptionModal";
import ReactMarkdown from "react-markdown";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PremiumFeatureModal.css";

type Props = {
  featureName: string;
};

const getRandomLandscapeImage = () =>
  landscapes[Math.floor(Math.random() * landscapes.length)];

export const PremiumFeatureModal = ({
  context,
  id,
}: ContextModalProps<Props>) => {
  const { t } = useTranslation();
  const modals = useModals();

  const openGpxViewerAppsModal = useCallback(() => {
    modals.openContextModal("gpxViewerAppsModal", {
      title: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Text>{t("gpx_viewer_apps_modal.title")}</Text>
        </div>
      ),
      innerProps: {},
      padding: 0,
      styles: {
        header: { padding: 20, marginBottom: 0 },
      },
      size: "xl",
    });
  }, []);

  const handleSubscribe = () => {
    context.closeModal(id);
    openGpxViewerAppsModal();
  };

  return (
    <>
      <Image src={getRandomLandscapeImage()} fit="contain" />
      <TypographyStylesProvider>
        <Stack p="lg">
          <ScrollArea type="auto" className="trkbk-premium-modal-content">
            <div className="trkbk-bottom-offset-small">
              <ReactMarkdown skipHtml>
                {t("subscription_modal.intro_paragraph")}
              </ReactMarkdown>
              <ReactMarkdown skipHtml>
                {t("subscription_modal.trackbook_features_paragraph")}
              </ReactMarkdown>
              <div className="trkbk-bottom-offset-medium">
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.satellite_map")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.3d_view")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.display_data_on_track")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.storage")}
                  </ReactMarkdown>
                </Text>
              </div>
              <ReactMarkdown skipHtml>
                {t("subscription_modal.gpx_viewer_online_maps_paragraph")}
              </ReactMarkdown>
              <div className="trkbk-bottom-offset-medium">
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.mapbox_online_maps")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.thunderforest_online_maps")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.here_online_maps")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.mapycz_online_maps")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.openweather_online_maps")}
                  </ReactMarkdown>
                </Text>
              </div>
              <ReactMarkdown skipHtml>
                {t("subscription_modal.sync_features")}
              </ReactMarkdown>
              <div className="trkbk-bottom-offset-medium">
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.upload_tracks_trackbook")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.edit_metadata_trackbook")}
                  </ReactMarkdown>
                </Text>
                <Text>
                  <FontAwesomeIcon icon={faCheckCircle} color="#7EC882" />
                  <>&nbsp;&nbsp;</>
                  <ReactMarkdown
                    className="trkbk-premium-modal-list-item"
                    skipHtml
                  >
                    {t("subscription_modal.delete_tracks_trackbook")}
                  </ReactMarkdown>
                </Text>
              </div>
            </div>
          </ScrollArea>
          <Button onClick={handleSubscribe} data-autofocus size="md">
            <Text>{t("subscription_modal.subscribe_button")}</Text>
          </Button>
        </Stack>
      </TypographyStylesProvider>
    </>
  );
};
