import axios from "axios";

const ALLOWED_CONTENT_TYPES = ["image/jpeg", "image/png", "image/gif"];

const getImageIfImageContentType = async (url: string) => {
  if (!url.startsWith("http")) throw new Error("must use http/https protocol");
  try {
    const res = await axios.head(url);
    const contentType = res.headers?.["content-type"];
    const isImage = contentType
      ? ALLOWED_CONTENT_TYPES.includes(contentType)
      : false;
    if (!isImage) throw new Error("not an image");
    return url;
  } catch (error) {
    throw new Error("request failed");
  }
};

export const getUrlsIfAreValidImages = async (urls: string[]) => {
  const preparedPromises = urls.map((url) => getImageIfImageContentType(url));
  const results = await Promise.allSettled(preparedPromises);
  return results.reduce<string[]>((acc, result) => {
    if (result.status === "fulfilled") {
      return [...acc, result.value];
    }
    return acc;
  }, []);
};
