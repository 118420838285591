import { faMountain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "components/UI/Tooltip";
import { useTranslation } from "react-i18next";
import { toggleCesium } from "stores/genericStore/actions";
import {
  useGenericDispatch,
  useGenericState,
} from "stores/genericStore/GenericContext";
import { useRouting } from "stores/routingStore/RoutingContext";

import { CollapsibleTopMenuActionIcon } from "../../../../components/UI/TopMenu/CollapsibleActionIcon";

export const CesiumMapToggleIcon = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useGenericDispatch();
  const { showCesiumMap } = useGenericState();
  const { t } = useTranslation();
  const {
    state: { computedTrackpoints },
  } = useRouting();

  const toggleCesiumMap = () => dispatch(toggleCesium());

  return (
    <Tooltip label={t("editmode.toggle_cesium_map_button_tooltip")}>
      <div>
        <CollapsibleTopMenuActionIcon
          isOpen={isOpen}
          onClick={toggleCesiumMap}
          active={showCesiumMap}
          disabled={computedTrackpoints.length < 2}
        >
          <FontAwesomeIcon icon={faMountain} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  );
};
