import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useCallback, useMemo } from "react";
import { useModals } from "@mantine/modals";
import { Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/UI/Tooltip";
import { useOptions } from "stores/optionsStore/OptionsContext";

import "./PremiumFeatureWrapper.css";
import { useGenericModals } from "hooks/useGenericModals";
import { useAuth } from "hooks/useAuth";

interface PremiumFeatureWrapperProps {
  featureName: string;
  /**
   * If the overlay background color is dimmed like an inactive button
   */
  isDimmed?: boolean;
  hasTooltip?: boolean;
  isVisible?: boolean;
  lockIconStyles?: React.CSSProperties;
}

const TooltipContainer = ({
  children,
  featureName,
  hasTooltip,
}: {
  children: ReactNode;
  featureName: string;
  hasTooltip: boolean;
}) => {
  const { t } = useTranslation();

  return hasTooltip ? (
    <Tooltip label={`${featureName} (${t("subscription_modal.title")})`}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export const PremiumFeatureWrapper = (
  props: React.PropsWithChildren<PremiumFeatureWrapperProps>
) => {
  const {
    children,
    featureName,
    isVisible = true,
    lockIconStyles,
    isDimmed = true,
    hasTooltip = true,
  } = props;
  const {
    state: { allowPremiumFeatures },
  } = useOptions();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { openInfoModal } = useGenericModals();
  const modals = useModals();
  const theme = useMantineTheme();

  const handleClick = useCallback(() => {
    if (user !== null) {
      modals.openContextModal("premiumFeatureModal", {
        title: (
          <Text>{`${t("subscription_modal.title")} 🚡 (${featureName})`}</Text>
        ),
        innerProps: { featureName },
        padding: 0,
        styles: {
          header: { padding: 20, marginBottom: 0 },
        },
        size: "xl",
      });
    } else {
      openInfoModal(
        t("subscription_modal.title"),
        t("online_services.guest_user_info_modal_text")
      );
    }
  }, [featureName, modals, t, user]);

  const overlayBackgroundColor = useMemo(
    () =>
      isDimmed
        ? theme.colorScheme === "dark"
          ? "#000"
          : "#eee"
        : "transparent",
    [isDimmed, theme.colorScheme]
  );

  return allowPremiumFeatures ? (
    <>{children}</>
  ) : (
    <TooltipContainer featureName={featureName} hasTooltip={hasTooltip}>
      <div
        style={{
          float: "left",
          position: "relative",
          display: isVisible ? "block" : "none",
        }}
        onClick={handleClick}
      >
        <div
          className="trkbk-locked-wrapper-overlay"
          style={{
            backgroundColor: overlayBackgroundColor,
          }}
        ></div>
        <FontAwesomeIcon
          className="trkbk-locked-wrapper-icon"
          style={lockIconStyles}
          icon={faLock}
        />
        {children}
      </div>
    </TooltipContainer>
  );
};
