 
import { useCallback, useEffect, useRef, useState } from 'react'

import { MARKER_COLORS, TRACK_COLORS } from 'config/constants'
import { Routing } from 'config/enums/routings'
import equal from 'fast-deep-equal'
import { ActionTimeout } from 'hooks/useActionTimeout'
import { useTrackStats } from 'hooks/useTrackStats'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { t } from 'i18next'
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'lrm-mapzen'
import './RoutingControl.css'
import { useMap, useMapEvents } from 'react-leaflet'
import {
  addTrackWaypoint,
  changeShowPlaceholderTrack,
  deleteTrackWaypoint,
  rewriteComputedTrackpoints,
  rewriteComputedTrackpointsWithAttributes,
  rewriteTrackmeta,
  rewriteTrackWaypoints,
  setIsRouting,
} from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { ComputedTrackPointsWithAttributes } from 'stores/routingStore/state'
import { Coords } from 'types/app'
import { smoothDataGauss } from 'utils/formatters/gaussianSmoothing'
import { showErrorNotification } from 'utils/notifications/customNotifications'

import { numericMarkerIcon } from '../Icons'
import { RoutablePolylinePlaceholder } from './components/RoutablePolylinePlaceholder'
import { useUserTracks } from 'hooks/useUserTracks'

const NAV_BASE_API_URL = import.meta.env.VITE_NAV_API_BASE

type Maneuver = {
  begin_shape_index: number
  end_shape_index: number
  index: number
  length: number
  time: number
}

/**
 * Handles displaying and control of routable trackpoints
 */
export const RoutingControl = ({ actionTimeout }: { actionTimeout: ActionTimeout }) => {
  const [control, setControl] = useState<L.Routing.Control | null>(null)
  const map = useMap()
  const {
    state: {
      trackWaypoints,
      computedTrackpoints,
      routing,
      showPlaceholderTrack,
      trackColor,
      computedTrackpointsWithAttributes,
      costingOptions,
    },
    dispatch,
    fetchElevations,
  } = useRouting()
  const { updateTrackStatsWithElevations, updateTrackStatsWithTrackMeta } = useTrackStats()
  const currentTrackWaypoints = useRef<Coords[]>([])
  const computedTrackpointsRef = useRef(computedTrackpoints)
  const computedTrackpointsWithAttributesRef = useRef(computedTrackpointsWithAttributes)
  const { isAddingWaypoint } = useUserWaypoints()
  const {selectedTrack} = useUserTracks();
  useMapEvents({
    click: (e) => {
      if (!actionTimeout.timeoutHasPassed()) return
      if (isAddingWaypoint) return
      const coords: Coords = [e.latlng.lat, e.latlng.lng]
      dispatch(addTrackWaypoint(coords))
    },
  })

  useEffect(() => {
    computedTrackpointsRef.current = computedTrackpoints
  }, [computedTrackpoints])
  useEffect(() => {
    computedTrackpointsWithAttributesRef.current = computedTrackpointsWithAttributes
  }, [computedTrackpointsWithAttributes])

  useEffect(() => {
    if (!control) return

    control.getRouter().options.routingOptions.costing = routing
    control.route()
  }, [routing, control])

  const getTrackColor = useCallback(() => trackColor || TRACK_COLORS.routable, [trackColor])

  useEffect(() => {
    if (!control || !selectedTrack || selectedTrack?.color === trackColor) return
    const controlOptions = control.options as L.Routing.RoutingControlOptions
    const lineStyles = controlOptions?.lineOptions?.styles

    if (lineStyles && lineStyles.length > 2) {
      const mainDisplayedLine = lineStyles[2]
      if (mainDisplayedLine) {
        mainDisplayedLine.color = getTrackColor()
      }
    }

    if (trackWaypoints.length > 1) {
      const selectedRoute = control._selectedRoute;

      if (selectedRoute) {
        control._clearLines()
        control._updateLines({ route: selectedRoute, alternatives: null })
      }
    }
  }, [control, selectedTrack, trackColor, trackWaypoints])

  useEffect(() => {
    if (trackWaypoints.length < 2) fetchElevations()
  }, [fetchElevations, trackWaypoints])

  /**
   * Synchronize control waypoints with waypoints from state
   * IMPORTANT: This fires the "waypointschanged" event.
   */
  useEffect(() => {
    if (!control) return
    currentTrackWaypoints.current = trackWaypoints

    const waypoints = control.getWaypoints()
    const updatedTrackWaypoints: Coords[] = waypoints.reduce(
      (acc, waypoint) => (waypoint.latLng ? [...acc, [waypoint.latLng.lat, waypoint.latLng.lng]] : acc),
      [] as Coords[]
    )

    if (!equal(trackWaypoints, updatedTrackWaypoints)) {
      control.setWaypoints(trackWaypoints.map((point) => new L.LatLng(point[0], point[1])))
    }
  }, [trackWaypoints, control])

  useEffect(() => {
    if (!map) return undefined

    const getCostingOptionsPayload = () => {
      switch (routing) {
        case Routing.bicycle: {
          return costingOptions[Routing.bicycle]
            ? {
                [Routing.bicycle]: {
                  bicycle_type: costingOptions[Routing.bicycle].bicycleType,
                  use_hills: costingOptions[Routing.bicycle].useHills,
                },
              }
            : undefined
        }
        case Routing.auto:
        case Routing.pedestrian:
        case Routing.none:
        default:
          return undefined
      }
    }

    const routingControl = new L.Routing.Control({
      router: L.Routing.mapzen('', {
        serviceUrl: `${NAV_BASE_API_URL}/route?`,
        costing: routing,
        costing_options: getCostingOptionsPayload(),
      }),
      plan: new L.Routing.Plan([], {
        createMarker: (waypointIndex, waypoint, numberOfWaypoints) => {
          actionTimeout.setTime()
          let markerColor = MARKER_COLORS.route
          if (waypointIndex === 0) {
            markerColor = MARKER_COLORS.start
          } else if (waypointIndex === numberOfWaypoints - 1) {
            markerColor = MARKER_COLORS.end
          }

          const marker = L.marker(waypoint.latLng, {
            icon: numericMarkerIcon(markerColor, waypointIndex + 1),
            draggable: true,
          })

          marker.on('dragend', () => {
            actionTimeout.setTime()
          })

          marker.on('click', () => {
            // We need to clear the routing loader here because it would
            // just go on forever even if there's just one marker left
            if (numberOfWaypoints === 2) {
              dispatch(setIsRouting(false), false)
            }

            if (actionTimeout.timeoutHasPassed()) {
              dispatch(deleteTrackWaypoint(waypointIndex))
            }
          })

          return marker
        },
      }),
      formatter: L.Routing.mapzenFormatter(),
      waypoints: [],
      serviceUrl: NAV_BASE_API_URL,
      routeLine: (route, options) => {
        const line = L.Routing.line(route, options)
        return line
      },
      lineOptions: {
        styles: [
          { color: 'black', opacity: 0.2, weight: 10 },
          { color: 'white', opacity: 0.8, weight: 8 },
          { color: getTrackColor(), opacity: 1, weight: 4 },
        ],
        extendToWaypoints: true,
        missingRouteTolerance: 10,
      },
      fitSelectedRoutes: false,
      show: false,
    })

    map.addControl(routingControl)
    setControl(routingControl)
    return () => {
      map.removeControl(routingControl)
    }
    // The previous developer put this comment here because some of the deps
    // kept re-rendering the app but it was obviously not the best solution.
    // For now, only 'actionTimeout' is making problems so it will need to
    // be checked out during some future refactoring.
  }, [map, costingOptions, routing])

  useEffect(() => {
    if (!control) return

    /**
     * waypointschanged event is the only way to detect addition of a new waypoint by dragging the route.
     * However, its fired for all changes, even manual changes triggered by .setWaypoints()
     * To prevent an indefinite loop, we have to check (deeply) if the trackWaypoints really changed and whether action should
     * be emitted or not.
     */
    control.on('waypointschanged', () => {
      const waypoints = control.getWaypoints()
      const updatedTrackWaypoints: Coords[] = waypoints.reduce((acc, waypoint) => {
        if (waypoint.latLng) {
          return [...acc, [waypoint.latLng.lat, waypoint.latLng.lng]]
        }
        return acc
      }, [] as Coords[])

      if (!equal(updatedTrackWaypoints, currentTrackWaypoints.current)) {
        dispatch(rewriteTrackWaypoints(updatedTrackWaypoints))
      }

      const validWaypointsCount = updatedTrackWaypoints.length
      if (validWaypointsCount < 2) {
        dispatch(rewriteComputedTrackpoints([]), false)
      }
    })

    control.on('routingstart', () => {
      dispatch(setIsRouting(true), false)
    })

    control.on('routesfound', (e) => {
      dispatch(setIsRouting(false), false)
      if (e.routes.length > 0) {
        const route = e.routes[0]
        const maneuvers: Maneuver[] = route.instructions
        const trackpoints: Coords[] = route.coordinates.map((coord: L.LatLng): Coords => [coord.lat, coord.lng])
        const startTimestamp = Date.now()
        const trackpointTimes: number[] = []
        let totalTimeElapsed = 0

        let pointsWithAttributes: ComputedTrackPointsWithAttributes[] = trackpoints.map((coords, index) => {
          const currentManeuver = maneuvers.find((maneuver, iteratorIndex) => {
            const nextManeuver = maneuvers[iteratorIndex + 1]
            if (!nextManeuver) return true
            return maneuver.index <= index && index < nextManeuver.index
          }) as Maneuver
          // Time from the router should be in seconds
          const maneuverShapesDiff = currentManeuver.end_shape_index - currentManeuver.begin_shape_index
          totalTimeElapsed +=
            index === 0 || currentManeuver.time === 0 || maneuverShapesDiff === 0
              ? 0
              : currentManeuver.time / maneuverShapesDiff
          const currentSectionAvgSpeed =
            currentManeuver.length === 0 || currentManeuver.time === 0
              ? 0
              : (currentManeuver.length * 1000) / currentManeuver.time

          trackpointTimes.push(totalTimeElapsed)

          return {
            coords,
            // Relative time for each trackpoint distance in seconds (needed for the chart)
            time: totalTimeElapsed,
            speed: currentSectionAvgSpeed,
          }
        })
        if (
          equal(trackpoints, computedTrackpointsRef.current) &&
          equal(computedTrackpointsWithAttributesRef.current, pointsWithAttributes)
        ) {
          return
        }

        // This is not optimal from performance standpoint but we need the whole
        // trackpoint data with attributes beforehand for the smoothing algorithm to work
        const pointsCountLengthRatio = pointsWithAttributes.length / route.summary.totalDistance
        const kernelSize = 3 + pointsCountLengthRatio / 40
        const smoothedSpeedData = smoothDataGauss(pointsWithAttributes, 'speed', kernelSize)

        if (smoothedSpeedData) {
          pointsWithAttributes = pointsWithAttributes.map((pointWithAttributes, index) => ({
            ...pointWithAttributes,
            speed: smoothedSpeedData[index],
          }))
        }

        const trackMeta = {
          totalDistance: route.summary.totalDistance,
          trackPointsSpeed: smoothedSpeedData,
          trackPointsTime: {
            datapoints: trackpointTimes,
            // Since all routable tracks get time data from the router, start point index should always be 0
            startPointIndex: 0,
            // Having a start timestamp here is handy if we need to compute absolute time
            // for trackpoint times in form of a timestamp; start timestamp is equivalent of the time when
            // the route was found by the router
            startTimestamp,
          },
          // Total time from the router should be in seconds
          totalTime: route.summary.totalTime,
        }

        fetchElevations(trackpoints).then((elevations) => {
          if (elevations) {
            updateTrackStatsWithElevations(elevations, trackpoints)
            updateTrackStatsWithTrackMeta(trackMeta)
          }
        })

        dispatch(
          [
            rewriteComputedTrackpointsWithAttributes(pointsWithAttributes),
            rewriteComputedTrackpoints(trackpoints),
            changeShowPlaceholderTrack(false),
            rewriteTrackmeta(trackMeta),
          ],
          false
        )
      }
    })

    control.on('routingerror', (e) => {
      const errorRes = JSON.parse(e.error.message)
      const errorMessage = errorRes.error

      showErrorNotification({
        title: t('errors.routing_error.title'),
        message: errorMessage || t('errors.routing_error.text'),
      })
    })
  }, [control, dispatch, fetchElevations])

  return showPlaceholderTrack && computedTrackpoints.length > 2 ? (
    <RoutablePolylinePlaceholder color={getTrackColor()} positions={computedTrackpoints} />
  ) : null
}
