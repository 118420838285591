import { useEffect, useMemo, useRef } from "react";

import { UpdateWaypointData } from "hooks/useUserWaypoints";
import { Icon, LeafletEventHandlerFn } from "leaflet";
import { Marker } from "react-leaflet";
import { Coords, Waypoint } from "types/app";
import { getIcon } from "utils/helpers/icons/getIcon";
import { getWaypointIconByKey } from "utils/helpers/icons/getWaypointIconName";

import { defaultIcon } from "./Icons";
import { WaypointInfoPopup } from "./WaypointInfoPopup/WaypointInfoPopup";

type WaypointMarkerProps = {
  waypoint: Waypoint;
  selected?: boolean;
  selectWaypoint: (id: Waypoint["id"] | null) => void;
  updateWaypoint: (id: Waypoint["id"], data: UpdateWaypointData) => void;
};

export const WaypointMarker = ({
  waypoint,
  selected,
  selectWaypoint,
  updateWaypoint,
}: WaypointMarkerProps) => {
  const markerRef = useRef<L.Marker | null>(null);
  const iconName = getWaypointIconByKey(waypoint.icon);
  const icon: Icon = iconName ? getIcon(iconName) : defaultIcon;
  const { links } = waypoint;
  const selectedRef = useRef(selected);
  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  const handleMove: LeafletEventHandlerFn = (e) => {
    const { lat, lng } = e.target.getLatLng();
    const waypointCoords: Coords = [lat, lng];
    updateWaypoint(waypoint.id, { point: waypointCoords });
    if (selectedRef.current) {
      markerRef.current?.openPopup();
    }
  };

  useEffect(() => {
    if (selected) {
      markerRef.current?.openPopup();
    } else {
      markerRef.current?.closePopup();
    }
  }, [selected]);

  const handlePopupClose = () => {
    selectWaypoint(null);
  };

  const handleClick = () => {
    if (selectedRef.current) {
      selectWaypoint(null);
    } else {
      selectWaypoint(waypoint.id);
    }
  };

  const MarkerWithPopup = () => (
    <Marker
      ref={markerRef}
      key={waypoint.id}
      draggable
      position={waypoint.point}
      icon={icon}
      eventHandlers={{
        click: handleClick,
        moveend: handleMove,
      }}
    >
      <WaypointInfoPopup
        heading={waypoint.name}
        description={waypoint.desc}
        links={links}
        onPopupCloseClick={handlePopupClose}
      />
    </Marker>
  );

  const memoized = useMemo(MarkerWithPopup, [waypoint]);

  return memoized;
};
