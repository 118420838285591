import { useEffect, useRef, useState } from "react";

import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { User } from "types/app";
import { getQueryParam, getQueryTrackID } from "utils/getQueryParam";

import { useAuthModals } from "./useAuthModals";
import { useGenericModals } from "./useGenericModals";
import { useLiftedModalContext } from "./useLiftedModalContext";

/**
 * Handles initial modals (welcome, verified/non-verified)
 */
export const useInitialModals = (user: User | null) => {
  const { openWelcomeModal, openInfoModal, openErrorModal } =
    useGenericModals();
  const { openSetNewPasswordModal } = useAuthModals();
  const [isWelcomeModalHidden] = useLocalStorage({
    key: "hideWelcomeModal",
    defaultValue: false,
  });
  const didRunWithModalsInContext = useRef(false);
  const openedViaSharedLink = useRef(false);
  const { modals } = useLiftedModalContext();
  const { t } = useTranslation();
  const [verifiedQueryParam] = useState(getQueryParam("verified"));
  const [passwordreset] = useState(getQueryParam("passwordreset"));

  useEffect(() => {
    const trackId = getQueryTrackID();

    if (trackId) {
      openedViaSharedLink.current = true;
    }
  }, [])

  useEffect(() => {
    if (!modals || didRunWithModalsInContext.current) return;

    if (passwordreset) {
      openSetNewPasswordModal(passwordreset);
      return;
    }

    if (!verifiedQueryParam) {
      if (
        !isWelcomeModalHidden &&
        !didRunWithModalsInContext.current &&
        !user
      ) {
        didRunWithModalsInContext.current = true;

        // Don't open the modal if user opened the app via a shared track link
        if (!openedViaSharedLink.current) {
          openWelcomeModal();
        }
      }
      return;
    }
    const isVerified = Number(verifiedQueryParam) === 1;

    if (isVerified) {
      didRunWithModalsInContext.current = true;
      openInfoModal(t("verified_modal.title"), t("verified_modal.body"));
    } else {
      didRunWithModalsInContext.current = true;
      openErrorModal({
        title: t("not_verified_modal.title"),
        text: t("not_verified_modal.body"),
      });
    }
  }, [modals, openErrorModal, openInfoModal, openWelcomeModal, openedViaSharedLink.current, t]);
};
