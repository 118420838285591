import { useCallback, useMemo, useState } from "react";

import { faFile, faUpload, faX } from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  Button,
  Group,
  MantineTheme,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useTranslation } from "react-i18next";
import { ImageError, ImageTarget, useImageUpload } from "hooks/useImageUpload";
import { ProgressBar } from "components/UI/ProgressBar";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { useUserTracks } from "hooks/useUserTracks";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { captureMessage } from "@sentry/react";
import { updateNonSaveableOptions } from "stores/optionsStore/actions";
import { AxiosError } from "axios";

const ACCEPTED_IMAGE_TYPE = IMAGE_MIME_TYPE.filter(
  (type) => type !== "image/svg+xml" && type !== "image/webp"
);

type DropStatus = "accepted" | "rejected" | "idle";

const getIconColor = (status: DropStatus, theme: MantineTheme) => {
  if (status === "accepted") {
    return theme.colors[theme.primaryColor]![
      theme.colorScheme === "dark" ? 4 : 6
    ];
  }
  if (status === "rejected") {
    return theme.colors.red[theme.colorScheme === "dark" ? 4 : 6];
  }
  return theme.colors.dark[0];
};

const FileUploadIcon = ({
  status,
  ...props
}: Partial<FontAwesomeIconProps> & { status: DropStatus }) => {
  if (status === "accepted")
    return <FontAwesomeIcon {...props} icon={faUpload} />;
  if (status === "rejected") return <FontAwesomeIcon {...props} icon={faX} />;
  return <FontAwesomeIcon {...props} icon={faFile} />;
};

type DropzoneChildrenProps = {
  status: DropStatus;
  theme: MantineTheme;
  fileName?: string;
};

export const DropzoneChildren = ({
  status,
  theme,
  fileName,
}: DropzoneChildrenProps) => {
  const {
    state: { storageSize },
  } = useOptions();
  const { t } = useTranslation();

  const UploadText = useMemo(() => {
    if (storageSize?.cantUpload === true) {
      return (
        <Text size="sm" color="red" mt={7}>
          {t("image_upload_modal.cant_upload_image_text")}
        </Text>
      );
    } else {
      return (
        <>
          <Text size="xl" inline>
            {t("image_upload_modal.upload_image_title")}
          </Text>
          <Text size="sm" color="dimmed" mt={7}>
            {t("image_upload_modal.upload_image_text", {
              size: storageSize?.uploadLimit ?? 10,
              types: "gif, jpeg, png",
            })}
          </Text>
        </>
      );
    }
  }, [storageSize]);

  return (
    <Group
      position="center"
      spacing="xl"
      style={{ minHeight: 100, pointerEvents: "none", flexWrap: "nowrap" }}
    >
      <FileUploadIcon
        status={status}
        style={{ color: getIconColor(status, theme) }}
        fontSize={30}
      />
      <div style={{ flexShrink: 1 }}>
        {fileName ? (
          <Text size="xl" inline>
            {fileName}
          </Text>
        ) : (
          UploadText
        )}
      </div>
    </Group>
  );
};

export interface UploadImageModalProps {
  extraMessage?: string;
  onClose?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  target?: ImageTarget;
  refetchStorageSize?: boolean;
}

export const UploadImageModal = ({
  target,
  extraMessage,
  onClose,
  onError,
  onSuccess,
  refetchStorageSize = true,
}: UploadImageModalProps) => {
  const theme = useMantineTheme();
  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string | null>(null);
  const { updateTrack, selectedTrack } = useUserTracks();
  const { updateWaypoint, selectedWaypoint } = useUserWaypoints();
  const {
    dispatch,
    state: { storageSize },
  } = useOptions();
  const { remove, upload, uploading, getStorageSize } = useImageUpload();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const removeImage = useCallback(async (link: string) => {
    const regex = /=(.*)/;
    const match = link.match(regex);
    const imgName = match ? match[1] : undefined;

    if (imgName) {
      try {
        return await remove(imgName);
      } catch {
        throw new ImageError(link);
      }
    }

    throw new ImageError(link);
  }, []);

  const fetchStorageSize = useCallback(async () => {
    try {
      const size = await getStorageSize();

      dispatch(
        updateNonSaveableOptions({
          storageSize: size,
        })
      );
    } catch (error) {
      captureMessage(
        `Trackbook: Errors when getting storage size: ${
          (error as AxiosError | Error)?.message
        }`
      );
    }
  }, [dispatch, getStorageSize]);

  const handleUpload = useCallback(async () => {
    if (!file) return;

    try {
      if (target !== undefined) {
        setLoading(true);
      }

      const imgLink = await upload(file);

      try {
        if (target === "track" && selectedTrack) {
          await updateTrack(selectedTrack.id, {
            links: [...selectedTrack.links, imgLink],
          });
        } else if (target === "waypoint" && selectedWaypoint) {
          await updateWaypoint(selectedWaypoint.id, {
            links: [...selectedWaypoint.links, imgLink],
          });
        }

        if (refetchStorageSize) {
          fetchStorageSize();
        }

        if (onSuccess) {
          onSuccess();
        } else if (onClose) {
          onClose();
        }
      } catch {
        await removeImage(imgLink);
        throw new Error();
      }
    } catch (error: unknown) {
      if (error instanceof ImageError) {
        captureMessage(
          `Trackbook: ImageError: Error when deleting image - the link is missing but the image is still uploaded: ${error.link}`
        );
      }

      if (onError) {
        onError();
      } else if (onClose) {
        onClose();
      }
    }
  }, [file, refetchStorageSize, removeImage, selectedTrack, target]);

  const handleAccept = (files: File[]) => {
    setError(null);
    if (files.length > 0) setFile(files[0]);
  };

  const handleReject = (
    fileRejections: {
      errors: { code: string; message: string }[];
      file: File;
    }[]
  ) => {
    let errorMessage = "";
    const rejection = fileRejections[0];

    rejection.errors.forEach((error) => {
      const { code } = error;

      if (code === "file-invalid-type") {
        errorMessage += t("image_upload_modal.error_invalid_file_type") + " ";
      } else if (code === "file-too-large") {
        errorMessage += t("image_upload_modal.error_file_over_size_limit");
      }
    });

    setFile(undefined);
    setError(
      errorMessage.length
        ? errorMessage
        : t("image_upload_modal.couldnt_upload_image")
    );
  };

  return (
    <>
      <Stack align="center">
        <Dropzone
          sx={{
            width: "100%",
            borderColor: file ? theme.colors.green[5] : undefined,
            backgroundColor: file ? theme.colors.green[0] : undefined,
          }}
          accept={ACCEPTED_IMAGE_TYPE}
          onDrop={handleAccept}
          onReject={handleReject}
          maxSize={
            storageSize?.uploadLimit && storageSize?.uploadLimit > 0
              ? storageSize?.uploadLimit * 1024 ** 2
              : 0
          }
          multiple={false}
          loading={target !== undefined ? loading : uploading}
          disabled={storageSize?.cantUpload === true}
        >
          <Dropzone.Accept>
            <DropzoneChildren
              status="accepted"
              theme={theme}
              fileName={file?.name}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <DropzoneChildren
              status="rejected"
              theme={theme}
              fileName={file?.name}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <DropzoneChildren
              status="idle"
              theme={theme}
              fileName={file?.name}
            />
          </Dropzone.Idle>
        </Dropzone>
        {error && (
          <Text size="sm" color="red">
            {error}
          </Text>
        )}
        {extraMessage !== undefined ? (
          <Text size="sm">{extraMessage}</Text>
        ) : null}
        <div style={{ marginBottom: "10px", marginTop: "5px", width: "100%" }}>
          <ProgressBar value={storageSize?.percentage} />
          <Text size="xs" align="end" style={{ marginTop: "5px" }}>
            {t("image_upload_modal.used_space")}:{" "}
            {storageSize
              ? storageSize.storageUsed +
                " / " +
                storageSize.storageTotal +
                " MB"
              : t("image_upload_modal.unknown_used_space")}
          </Text>
        </div>
        <Button
          fullWidth
          onClick={handleUpload}
          disabled={!file || storageSize?.cantUpload === true}
          loading={target !== undefined ? loading : uploading}
        >
          {t("import_gpx_modal.upload_button_label")}
        </Button>
      </Stack>
    </>
  );
};
