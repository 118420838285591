import { useState } from "react";

import axios from "axios";
import { useOptions } from "stores/optionsStore/OptionsContext";

import { useAuth } from "./useAuth";

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL ?? "";
export const UPLOAD_FILE_SIZE_LIMIT = 10; // MB

export class ImageError extends Error {
  link: string;

  constructor(link: string) {
    super();

    this.link = link;
  }
}

export class LinkError extends Error {}

export type ImageTarget = "track" | "waypoint";

export interface StorageSize {
  cantUpload: boolean;
  percentage: number;
  storageTotal: number;
  storageUsed: number;
  uploadLimit: number;
}

export const useImageUpload = () => {
  const [uploading, setUploading] = useState(false);
  const { user } = useAuth();
  const {
    state: { language },
  } = useOptions();

  const upload = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!user) throw new Error("Not signed in");

      setUploading(true);

      const reader = new FileReader();

      reader.onload = async (e) => {
        const contents = e.target?.result as ArrayBuffer | null | undefined;
        if (!contents) throw new Error("File empty");

        try {
          const uint8Array = new Uint8Array(contents);
          const img = window.btoa(
            uint8Array.reduce(
              (acc, current) => acc + String.fromCharCode(current),
              ""
            )
          );

          const response = await axios.post(
            `${BASE_API_URL}/upload-image`,
            {
              img: img,
              type: file.type,
              agent: "Trackbook",
              lng: language,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              validateStatus: (status) =>
                (status >= 200 && status < 300) || status === 404,
              withCredentials: true,
            }
          );

          if (response?.data?.link) {
            setUploading(false);
            resolve(response.data.link);
          }

          reject(new Error("No data returned from the server"));
        } catch (error) {
          setUploading(false);
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });

  const remove = async (imgName: string): Promise<void> => {
    const response = await axios.post(
      `${BASE_API_URL}/delete-image`,
      {
        imgName,
        agent: "Trackbook",
        lng: language,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
        withCredentials: true,
      }
    );

    // axios has a problem with returning a proper error response that the try/catch would catch, so we need to hackfix it
    if (response && response.status && response.status === 404) {
      throw new Error();
    }
  };

  const getStorageSize = async (): Promise<StorageSize> => {
    const response = await axios.post<{
      storageTotal: string;
      storageUsed: string;
    }>(
      `${BASE_API_URL}/get-storage-info`,
      {
        agent: "Trackbook",
        lng: language,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
        withCredentials: true,
      }
    );

    if (!response?.data?.storageTotal || !response?.data?.storageUsed) {
      throw new Error("Received incomplete data!");
    }

    const storageTotal = Number(response.data.storageTotal);
    const storageUsed = Number(response.data.storageUsed);

    const storageDiff = storageTotal - storageUsed;
    const cantUpload = storageUsed >= storageTotal;
    const percentage = (100 / storageTotal) * storageUsed;
    const uploadLimit =
      storageDiff < UPLOAD_FILE_SIZE_LIMIT
        ? storageDiff < 0
          ? 0
          : storageDiff
        : UPLOAD_FILE_SIZE_LIMIT;

    return {
      cantUpload,
      percentage,
      storageTotal,
      storageUsed,
      uploadLimit,
    };
  };

  return { getStorageSize, remove, upload, uploading, setUploading };
};
